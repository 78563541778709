export const graphqlTalentFields = `
    id
    user_id
    date_of_birth
    gender_id
    nationality_code
    pseudonym
    identifier
    can_sign_salary
    can_sign_independant
    address_street_1
    address_street_2
    address_city_id
    address_state
    address_zip_code
    address_country_code
    linkedin
    skype
    twitter
    website
    facebook
    presentation_video
    current_job_title_id
    education_level_id
    professional_experience_years
    about
    preferred_salary
    salary_currency_code
    employed_salary
    employed_salary_currency_code
    contractor_salary
    contractor_salary_currency_code
    search_urgency_id
    availability_id
    preferred_language_code
    subscribed_to_newsletter
    subscribed_to_notifications
    avatar_id
    has_no_experience
    has_no_certification
    has_no_hobby
    completed_education
    completed_experience
    completed_certification
    iban
    payment_mode
    profile_status
    presentation
    description
    currency_symbol
    document_count
    document_id_card
    document_address
    talent_mother
    talent_father
    talent_cin
    talent_cin_date
    is_talenteum
    is_portage
    portage_contract_type_id
    is_sponsored
    cv_choice
    experiences {
        id
        talent_id
        job_title_id
        started_on
        ended_on
        still_working
        work_location_id
        company_name
        company_industry_id
        company_city_id
        company_country_code
        contract_time_id
        contract_level_id
        contract_type_id
        description
        job_title { id name }
        work_location { id name }
        industry { id name }
        city { name }
        country { name }
        contract_time { id name }
        contract_level { id name }
        contract_type { id name }
    }
    certifications {
        id
        name
        pivot {
            id
            talent_id
            certification_id
            obtained_on
            can_expire
            expires_on
            last_update
            certified_by
            identifier
            url
        }
    }
    educations {
        id
        name 
        pivot {
            id
            talent_id
            education_id
            university_id
            city_id
            status_id
            country_code
            started_on
            ended_on
            diploma_id
            diploma { id type filename }
            description
            university { name }
            city { name }
            status { id name }
            country { name }
        }
    }
    
    contract_types {
        id
        
        name
    }
    
    hobbies { id name pivot { level } }
    
    salaries {
        id

        talent_id

        currency_code

        salary

        cost

        allowances

        employed_cost
     
        employed_cost_details
      
        contractor_cost
       
        contractor_cost_details
    }
    
    skills { 
        id 
        name 
        pivot { 
          id
          level 
        } 
    }
    
    languages { 
     code
     name 
     pivot 
     { 
        id
        level 
     } 
   }
    
    work_location {
        id
        
        talent_id

        work_location_id
        
        name
    }
    nationality { code name }

    city { id name }

    country { code name }

    job_title {id name }

    job_title_seeking {id name }

    search_urgency { name }

    availability { name }
    
    preferred_language {
        code
    }

    avatar { id filename type}
    
    education_level {id name }
    
    ref_sector_id
    sector {id name }
    
    timezone_id
    
    timezone {
        id 
        name
        value 
    }
`;

export const graphqlJobOfferFields = `
    id
    identifier
    company_id
    company_contact_id
    budget
    currency_code
    availability_id
    job_title_id
    education_level_id
    industry_id
    experience_ranges_id
    professional_experience_years
    job_description
    status
    published_on
    expires_on
    cant_expire
    alert_company
    interview_template
    generate_status
    job_title { 
        id,
        name 
    }
    industry {
        id 
        name 
    }
    country {
        id 
        name  
    }
    search_urgency {
        id 
        name  
    }
    availability {
        id 
        name  
    }
    education_level {
        id 
        name  
    }

    skills { id name pivot { level } }
    languages {  code name pivot { level } }
    tasks { id description }
    benefits { id description }
    certifications { 
        id 
        name 
    }
    educations { 
        id 
        name 
    }
    preferred_contract_types { 
        id 
        name 
    }
    preferred_work_locations {  
        id
        name
        talent_name
        company_name 
    }
    preferred_countries { 
        code 
        name 
    }
    universities {  name }
    cities {  name }
    
    experience_range { name min max }
    
    company { 
       industry { name }
       avatar {filename} 
       values { name type  pivot { level } }
       value_orders { id type position } 
    }
    budgets { currency_code budget budget_mu cost }
    regions { id name }
    sub_regions { id name}
    questions
    time_start
    time_end
    is_flexible
    timezone_id
    timezone {
        id
        name
        value
    }
`

export const graphqlJobOfferPreviewFields = `
    id
    identifier
    company_id
    company_contact_id
    budget
    currency_code
    availability_id
    job_title_id
    education_level_id
    industry_id
    experience_ranges_id
    professional_experience_years
    job_description
    status
    published_on
    expires_on
    cant_expire
    alert_company
    interview_template
    generate_status
    job_title { 
        id,
        name 
    }
    industry {
        id 
        name 
    }
    country {
        id 
        name  
    }
    search_urgency {
        id 
        name  
    }
    availability {
        id 
        name  
    }
    education_level {
        id 
        name  
    }

    skills { id name pivot { level } }
    languages {  code name pivot { level } }
    tasks { id description }
    benefits { id description }
    certifications { 
        id 
        name 
    }
    educations { 
        id 
        name 
    }
    preferred_contract_types { 
        id 
        name 
    }
    preferred_work_locations {  
        id
        name
        talent_name
        company_name 
    }
    preferred_countries { 
        code 
        name 
    }
    universities {  name }
    cities {  name }
    
    experience_range { name min max }
    
    company { 
       industry { name }
       avatar {filename} 
       values { name type  pivot { level } }
       value_orders { id type position } 
    }
   
    budgets { currency_code budget budget_mu cost }
    regions { id name }
    sub_regions { id name}
    questions
    time_start
    time_end
    is_flexible
    timezone_id
    timezone {
        id
        name
        value
    }
`

export const graphqlModulesFields = `
    id
    name
    slug_key
    subModules {
        id
        name
        slug_key
        module_id
    }
`

export const graphqlGroupFields = `
    id
    name
    contacts {
        id
        first_name
        last_name
        company_position
        avatar { id filename type}
    }
    sub_modules {
        id
        name
        slug_key
        module_id
    }
    `

export const graphqlCompanyFields = `
    id
    name
    company_phone
    website
    registration_number
    address
    zip_code
    city_id
    industry_id
    avatar_id
    company_no
    vat_no
    values_done
    country_code
    description
    identifier
    can_sign_contract
    industry {
        id
        name
    }
    city {
        id
        name
    }
    country {
        name
    }
    values {
        id
        name
        type
        pivot {
          level
        }
    }
    value_orders { id type position }
    avatar { id filename type}
    collabs {
        id
        first_name
        last_name
        company_position
        email_status
        is_admin
        avatar { id filename type}
        user { email }
        updated_at
    }
    groups {
        ${graphqlGroupFields}
    }
`;

export const graphqlContactFields = `
    id
    identifier
    user_id
    company_id
    first_name
    last_name
    company_position
    date_of_birth
    whatsapp
    is_admin
    email_status
    gender_id
    document_count
    preferred_currency_code
    preferred_language_code
    subscribed_to_newsletter
    subscribed_to_notifications
    confidential_done
    avatar_id
    avatar {
        id
        filename
        gender_id
    }
    photo_path
    photo_name
    profile_status
    currency {
        name
        code
        symbol
    }
    language {
        name
    }
    professionalHobby {
        name
    }
    personalHobby {
        name
    }
    company {
        ${graphqlCompanyFields}
    }
    timezone_id
    timezone {
        id
        name
        value
    }
`;

export const graphqlJobApplicationFields = `
        id
        talent_id
        job_offer_id
        status
        step
        created_at
        dates_status
        tool
        link
        description_company
        rating_company
        description_talent
        rating_talent
        interview_fiche
        note
        evaluation
        times {
            id
            available_date
            available_date_gmt
            available_date_talent
            available_date_company
            timezone_id
            timezone {
                name
                value
            }
            status
            proposed_by
            jwt
            pwd
        }
        proposal {
            id
            talent_id
            job_offer_id
            status
            package
            package_counter
            package_contract
            package_contract_counter
            contract {
                signature_talent
                signature_company
            }
        }
   `;

export const graphqlJobProposalFields = `
        id
        talent_id
        job_offer_id
        status
        package
        package_counter
   `;

export const graphqlParrainageFields = `
    id
    first_name
    last_name
    email
    type
    company_name
    company_position
    user_id
    status
`

export const graphqlPortageFields = `
    id
    first_name
    last_name
    email
    phone
    salary
    job_title
    currency_code
    currency {
        name
        code
        symbol
    }
    country_code
    country {
        name
        code
    }
    type
    user_id
    start_date
    work_location_id
    work_location {
        id
        name
        talent_name
        company_name
    }
    company_name
    created_at
    job_offer_id
    job_offer_application_id
`

export const graphqlFullTalentFields = `
    id
    user_id
    first_name
    last_name
    date_of_birth
    gender_id
    nationality_code
    pseudonym
    identifier
    address_street_1
    address_street_2
    address_city_id
    address_state
    address_zip_code
    address_country_code
    linkedin
    skype
    twitter
    website
    facebook
    presentation_video
    current_job_title_id
    education_level_id
    professional_experience_years
    about
    preferred_salary
    salary_currency_code
    employed_salary
    employed_salary_currency_code
    contractor_salary
    contractor_salary_currency_code
    search_urgency_id
    availability_id
    preferred_language_code
    subscribed_to_newsletter
    subscribed_to_notifications
    avatar_id
    has_no_experience
    has_no_certification
    has_no_hobby
    completed_education
    completed_experience
    completed_certification
    iban
    payment_mode
    profile_status
    presentation
    description
    currency_symbol
    document_count
    document_id_card
    document_address
    is_talenteum
    is_portage
    portage_contract_type_id
    is_sponsored
    cv_choice
    can_sign_salary
    can_sign_independant
    id_card_number
    brn_number
    nif
    stat
    swift_code
    bank_acc
    invoice_entity
    employed_by
    employment_type
    no_holidays
    holiday_activation_date
    timesheet_frequency
    employment_job_title
    start_date
    department
    invoicing_period
    invoicing_period_rate
    invoicing_period_rate_currency
    contract_version
    job_offer_application_id
    experiences {
        id
        talent_id
        job_title_id
        started_on
        ended_on
        still_working
        work_location_id
        company_name
        company_industry_id
        company_city_id
        company_country_code
        contract_time_id
        contract_level_id
        contract_type_id
        description
        job_title { id name }
        work_location { id name }
        industry { id name }
        city { name }
        country { name }
        contract_time { id name }
        contract_level { id name }
        contract_type { id name }
    }
    certifications {
        id
        name
        pivot {
            id
            talent_id
            certification_id
            obtained_on
            can_expire
            expires_on
            last_update
            certified_by
            identifier
            url
        }
    }
    educations {
        id
        name 
        pivot {
            id
            talent_id
            education_id
            university_id
            city_id
            status_id
            country_code
            started_on
            ended_on
            diploma_id
            diploma { id type filename }
            description
            university { name }
            city { name }
            status { id name }
            country { name }
        }
    }
    
    contract_types {
        id
        
        name
    }
    
    hobbies { id name pivot { level } }
    
    salaries {
        id

        talent_id

        currency_code

        salary

        cost

        allowances

        employed_cost
     
        employed_cost_details
      
        contractor_cost
       
        contractor_cost_details
    }
    
    skills { 
        id 
        name 
        pivot { 
          id
          level 
        } 
    }
    
    languages { 
     code
     name 
     pivot 
     { 
        id
        level 
     } 
   }
    
    work_location {
        id
        
        talent_id

        work_location_id
        
        name
    }
    nationality { code name }

    city { id name }

    country { code name }

    job_title {id name }

    job_title_seeking {id name }

    search_urgency { name }

    availability { name }
    
    preferred_language {
        code
    }

    avatar { id filename type}
    
    education_level {id name }
    
    ref_sector_id
    sector {id name }
    
    timezone_id
    
    timezone {
        id 
        name
        value 
    }
`;

export const graphqlFullJobOfferFields = `
    id
    identifier
    company_id
    company_contact_id
    budget
    currency_code
    availability_id
    job_title_id
    education_level_id
    industry_id
    experience_ranges_id
    professional_experience_years
    job_description
    status
    published_on
    expires_on
    cant_expire
    alert_company
    interview_template
    generate_status
    job_title { 
        id,
        name 
    }
    industry {
        id 
        name 
    }
    country {
        id 
        name  
    }
    search_urgency {
        id 
        name  
    }
    availability {
        id 
        name  
    }
    education_level {
        id 
        name  
    }

    skills { id name pivot { level } }
    languages {  code name pivot { level } }
    tasks { id description }
    benefits { id description }
    certifications { 
        id 
        name 
    }
    educations { 
        id 
        name 
    }
    preferred_contract_types { 
        id 
        name 
    }
    preferred_work_locations {  
        id
        name
        talent_name
        company_name 
    }
    preferred_countries { 
        code 
        name 
    }
    universities {  name }
    cities {  name }
    
    experience_range { name min max }
    
    company { 
       industry { name }
       avatar {filename} 
       values { name type  pivot { level } }
       value_orders { id type position } 
    }
    company_contact {
        id
        first_name
        last_name
    }
    budgets { currency_code budget budget_mu cost }
    regions { id name }
    sub_regions { id name}
    questions
    time_start
    time_end
    is_flexible
`