/*=========== Common ==========*/
export const SET_COMMON_STATE = 'SET_COMMON_STATE'
export const FETCH_START = 'FETCH_START'
export const FETCH_START_WITHOUT_LOADING = 'FETCH_START_WITHOUT_LOADING'
export const FETCH_EXPIRED = 'FETCH_EXPIRED'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_ERROR = 'FETCH_ERROR'
export const FETCH_ERROR_CUSTOM = 'FETCH_ERROR_CUSTOM'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const SHOW_UNAUTH = 'SHOW_UNAUTH'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const HIDE_MESSAGE_UNAUTH = 'HIDE_MESSAGE_UNAUTH'
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER'
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER'
export const POST_SUCCESS = 'POST_SUCCESS'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

/*=========== Auth ==========*/
export const SET_AUTH_STATE = 'SET_AUTH_STATE'
export const RESET_PWD = 'RESET_PWD'
export const RESET_PWD_SUCCESS = 'RESET_PWD_SUCCESS'
export const SIGNIN_USER = 'SIGNIN_USER'
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS'
export const SIGNOUT_USER = 'SIGNOUT_USER'
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS'
export const SEND_OTP = 'SEND_OTP'
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS'
export const VALIDATE_OTP = 'VALIDATE_OTP'
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS'
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_RESEND_EMAIL = 'REGISTER_RESEND_EMAIL'
export const REGISTER_RESEND_EMAIL_SUCCESS = 'REGISTER_RESEND_EMAIL_SUCCESS'
export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const REGISTER_USER_LINKEDIN = 'REGISTER_USER_LINKEDIN'
export const REGISTER_USER_LINKEDIN_SUCCESS = 'REGISTER_USER_LINKEDIN_SUCCESS'
export const AUTO_LOGIN = 'AUTO_LOGIN'
/*=========== List ==========*/
export const SET_LIST_STATE = 'SET_LIST_STATE'
export const RESET_LIST_STATE = 'RESET_LIST_STATE'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_EDUCATION = 'GET_EDUCATION'
export const GET_EDUCATION_SUCCESS = 'GET_EDUCATION_SUCCESS'
export const GET_EDUCATION_STATUS = 'GET_EDUCATION_STATUS'
export const GET_EDUCATION_STATUS_SUCCESS = 'GET_EDUCATION_STATUS_SUCCESS'
export const GET_EDUCATION_LEVEL = 'GET_EDUCATION_LEVEL'
export const GET_EDUCATION_LEVEL_SUCCESS = 'GET_EDUCATION_LEVEL_SUCCESS'
export const GET_SEARCH_URGENCY = 'GET_SEARCH_URGENCY'
export const GET_SEARCH_URGENCY_SUCCESS = 'GET_SEARCH_URGENCY_SUCCESS'
export const GET_REF_JOB_TITLE = 'GET_REF_JOB_TITLE'
export const GET_REF_JOB_TITLE_SUCCESS = 'GET_REF_JOB_TITLE_SUCCESS'
export const GET_UNIVERSITY = 'GET_UNIVERSITY'
export const GET_UNIVERSITY_SUCCESS = 'GET_UNIVERSITY_SUCCESS'
export const GET_LANGUAGE = 'GET_LANGUAGE'
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS'
export const GET_SKILL = 'GET_SKILL'
export const GET_SKILL_SUCCESS = 'GET_SKILL_SUCCESS'
export const GET_AVAILABILITY = 'GET_AVAILABILITY'
export const GET_AVAILABILITY_SUCCESS = 'GET_AVAILABILITY_SUCCESS'
export const GET_CONTRACT_MODE = 'GET_CONTRACT_MODE'
export const GET_CONTRACT_MODE_SUCCESS = 'GET_CONTRACT_MODE_SUCCESS'
export const GET_CONTRACT_TYPE = 'GET_CONTRACT_TYPE'
export const GET_CONTRACT_TYPE_SUCCESS = 'GET_CONTRACT_TYPE_SUCCESS'
export const GET_CONTRACT_TIME = 'GET_CONTRACT_TIME'
export const GET_CONTRACT_TIME_SUCCESS = 'GET_CONTRACT_TIME_SUCCESS'
export const GET_CONTRACT_LEVEL = 'GET_CONTRACT_LEVEL'
export const GET_CONTRACT_LEVEL_SUCCESS = 'GET_CONTRACT_LEVEL_SUCCESS'
export const GET_WORK_LOCATION = 'GET_WORK_LOCATION'
export const GET_WORK_LOCATION_SUCCESS = 'GET_WORK_LOCATION_SUCCESS'
export const GET_AVATAR = 'GET_AVATAR'
export const GET_AVATAR_SUCCESS = 'GET_AVATAR_SUCCESS'
export const GET_GENDER = 'GET_GENDER'
export const GET_GENDER_SUCCESS = 'GET_GENDER_SUCCESS'
export const GET_CITIES = 'GET_CITIES'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CERTIFICATION = 'GET_CERTIFICATION'
export const GET_CERTIFICATION_SUCCESS = 'GET_CERTIFICATION_SUCCESS'
export const GET_SECTOR = 'GET_SECTOR'
export const GET_SECTOR_SUCCESS = 'GET_SECTOR_SUCCESS'
export const GET_INDUSTRY = 'GET_INDUSTRY'
export const GET_INDUSTRY_SUCCESS = 'GET_INDUSTRY_SUCCESS'
export const GET_SUB_INDUSTRY = 'GET_SUB_INDUSTRY'
export const GET_SUB_INDUSTRY_SUCCESS = 'GET_SUB_INDUSTRY_SUCCESS'
export const GET_SUB_SUB_INDUSTRY = 'GET_SUB_SUB_INDUSTRY'
export const GET_SUB_SUB_INDUSTRY_SUCCESS = 'GET_SUB_SUB_INDUSTRY_SUCCESS'
export const GET_EXP_RANGE = 'GET_EXP_RANGE'
export const GET_EXP_RANGE_SUCCESS = 'GET_EXP_RANGE_SUCCESS'
export const GET_HOBBY = 'GET_HOBBY'
export const GET_HOBBY_SUCCESS = 'GET_HOBBY_SUCCESS'
export const GET_CURRENCY = 'GET_CURRENCY'
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS'
export const GET_VALUE = 'GET_VALUE'
export const GET_VALUE_SUCCESS = 'GET_VALUE_SUCCESS'
export const GET_TIMEZONE = 'GET_TIMEZONE'
export const GET_TIMEZONE_SUCCESS = 'GET_TIMEZONE_SUCCESS'
export const GET_MODULES = 'GET_MODULES'
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS'

/*=========== Ref ==========*/
export const SET_REF_STATE = 'SET_REF_STATE'
export const CREATE_REF = 'CREATE_REF'
export const CREATE_REF_SUCCESS = 'CREATE_REF_SUCCESS'
export const CREATE_AUTO = 'CREATE_AUTO'
export const CREATE_AUTO_SUCCESS = 'CREATE_AUTO_SUCCESS'
export const RESET_REF = 'RESET_REF'
export const RESET_REF_SUCCESS = 'RESET_REF_SUCCESS'
export const TRACK_STATE = 'TRACK_STATE'
export const TRACK_STATE_SUCCESS = 'TRACK_STATE_SUCCESS'
/*=========== Talent ==========*/
export const SET_TALENT_STATE = 'SET_TALENT_STATE'
export const GET_TALENT = 'GET_TALENT'
export const GET_TALENT_SUCCESS = 'GET_TALENT_SUCCESS'
export const GET_TALENTS = 'GET_TALENTS'
export const GET_TALENTS_SUCCESS = 'GET_TALENTS_SUCCESS'
export const GET_TALENTS_ES = 'GET_TALENTS_ES'
export const GET_TALENTS_ES_RESET = 'GET_TALENTS_ES_RESET'
export const GET_TALENTS_ES_RESET_PAGE = 'GET_TALENTS_ES_RESET_PAGE'
export const GET_TALENTS_ES_SUCCESS = 'GET_TALENTS_ES_SUCCESS'
export const GET_TALENTS_COUNT = 'GET_TALENTS_COUNT'
export const GET_TALENTS_COUNT_SUCCESS = 'GET_TALENTS_COUNT_SUCCESS'
export const UPDATE_TALENT = 'UPDATE_TALENT'
export const UPDATE_TALENT_SUCCESS = 'UPDATE_TALENT_SUCCESS'
export const UPDATE_TALENT_MULTIPLE = 'UPDATE_TALENT_MULTIPLE'
export const UPDATE_TALENT_MULTIPLE_SUCCESS = 'UPDATE_TALENT_MULTIPLE_SUCCESS'
export const UPDATE_TALENT_MULTIPLE_LEVEL = 'UPDATE_TALENT_MULTIPLE_LEVEL'
export const UPDATE_TALENT_MULTIPLE_LEVEL_SUCCESS = 'UPDATE_TALENT_MULTIPLE_LEVEL_SUCCESS'
export const DELETE_TALENT_MULTIPLE = 'DELETE_TALENT_MULTIPLE'
export const DELETE_TALENT_MULTIPLE_SUCCESS = 'DELETE_TALENT_MULTIPLE_SUCCESS'
export const PRESENTATION_TALENT = 'PRESENTATION_TALENT'
export const PRESENTATION_TALENT_SUCCESS = 'PRESENTATION_TALENT_SUCCESS'
export const UPDATE_SEMI_PROFILE = 'UPDATE_SEMI_PROFILE'
export const UPDATE_SEMI_PROFILE_SUCCESS = 'UPDATE_SEMI_PROFILE_SUCCESS'
export const UPDATE_PROFILE_STATUS = 'UPDATE_PROFILE_STATUS'
export const UPDATE_PROFILE_STATUS_SUCCESS = 'UPDATE_PROFILE_STATUS_SUCCESS'
export const SALARY_DETAILS = 'SALARY_DETAILS'
export const SALARY_DETAILS_SUCCESS = 'SALARY_DETAILS_SUCCESS'
export const SALARY_TAX_DETAILS = 'SALARY_TAX_DETAILS'
export const SALARY_TAX_DETAILS_SUCCESS = 'SALARY_TAX_DETAILS_SUCCESS'
export const FIND_TALENT_APPLICATION = 'FIND_TALENT_APPLICATION'
export const FIND_TALENT_APPLICATION_SUCCESS = 'FIND_TALENT_APPLICATION_SUCCESS'
export const REFETCH_TALENT = 'REFETCH_TALENT'
export const GET_TIMESHEETS = 'GET_TIMESHEETS'
export const GET_TIMESHEETS_SUCCESS = 'GET_TIMESHEETS_SUCCESS'
export const UPSERT_TIMESHEETS = 'UPSERT_TIMESHEETS'
export const UPSERT_TIMESHEETS_SUCCESS = 'UPSERT_TIMESHEETS_SUCCESS'
export const GET_EXPENSE_REPORTS = 'GET_EXPENSE_REPORTS'
export const GET_EXPENSE_REPORTS_SUCCESS = 'GET_EXPENSE_REPORTS_SUCCESS'
export const UPSERT_EXPENSE_REPORTS = 'UPSERT_EXPENSE_REPORTS'
export const UPSERT_EXPENSE_REPORTS_SUCCESS = 'UPSERT_EXPENSE_REPORTS_SUCCESS'
export const DELETE_EXPENSE_REPORTS = 'DELETE_EXPENSE_REPORTS'
export const DELETE_EXPENSE_REPORTS_SUCCESS = 'DELETE_EXPENSE_REPORTS_SUCCESS'
export const GET_CONTRACTOR_INVOICE = 'GET_CONTRACTOR_INVOICE'
export const GET_CONTRACTOR_INVOICE_SUCCESS = 'GET_CONTRACTOR_INVOICE_SUCCESS'
export const UPSERT_CONTRACTOR_INVOICE = 'UPSERT_CONTRACTOR_INVOICE'
export const UPSERT_CONTRACTOR_INVOICE_SUCCESS = 'UPSERT_CONTRACTOR_INVOICE_SUCCESS'
export const GET_CONTRACTOR_INVOICE_EXPLAIN = 'GET_CONTRACTOR_INVOICE_EXPLAIN'
export const GET_CONTRACTOR_INVOICE_EXPLAIN_SUCCESS = 'GET_CONTRACTOR_INVOICE_EXPLAIN_SUCCESS'
export const GET_HOLIDAYS = 'GET_HOLIDAYS'
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS'
export const UPSERT_HOLIDAYS = 'UPSERT_HOLIDAYS'
export const UPSERT_HOLIDAYS_SUCCESS = 'UPSERT_HOLIDAYS_SUCCESS'
export const GET_BONUS = 'GET_BONUS'
export const GET_BONUS_SUCCESS = 'GET_BONUS_SUCCESS'
export const UPSERT_BONUS = 'UPSERT_BONUS'
export const UPSERT_BONUS_SUCCESS = 'UPSERT_BONUS_SUCCESS'
export const UPSERT_SURVEY = 'UPSERT_SURVEY'
export const UPSERT_SURVEY_SUCCESS = 'UPSERT_SURVEY_SUCCESS'

/*=========== File ==========*/
export const SET_FILE_STATE = 'SET_FILE_STATE'
export const GET_ALL_UPLOAD_FILES = 'GET_ALL_UPLOAD_FILES'
export const GET_ALL_UPLOAD_FILES_SUCCESS = 'GET_ALL_UPLOAD_FILES_SUCCESS'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_EXPENSE_REPORT = 'UPLOAD_FILE_EXPENSE_REPORT'
export const UPLOAD_FILE_EXPENSE_REPORT_SUCCESS = 'UPLOAD_FILE_EXPENSE_REPORT_SUCCESS'
export const UPLOAD_FILE_CONTRACTOR_INVOICE_SUCCESS = 'UPLOAD_FILE_CONTRACTOR_INVOICE_SUCCESS'
export const DELETE_FILE = 'DELETE_FILE'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'

/*=========== EduExpCert ==========*/
export const SET_EDU_EXP_CERT_STATE = 'SET_EDU_EXP_CERT_STATE'
export const UPDATE_EDU_EXP_CERT = 'UPDATE_EDU_EXP_CERT'
export const UPDATE_EDU_EXP_CERT_SUCCESS = 'UPDATE_EDU_EXP_CERT_SUCCESS'
export const SKILL_LANG_HOBBY = 'SKILL_LANG_HOBBY'
export const UPDATE_SKILL_LANG_HOBBY = 'UPDATE_SKILL_LANG_HOBBY'
export const UPDATE_SKILL_LANG_HOBBY_SUCCESS = 'UPDATE_SKILL_LANG_HOBBY_SUCCESS'

/*=========== Job Offers ==========*/
export const SET_JOB_OFFER_STATE = 'SET_JOB_OFFER_STATE'
export const GET_JOB_OFFERS = 'GET_JOB_OFFERS'
export const GET_JOB_OFFERS_COUNT = 'GET_JOB_OFFERS_COUNT'
export const GET_JOB_OFFERS_COUNT_SUCCESS = 'GET_JOB_OFFERS_COUNT_SUCCESS'
export const GET_JOB_OFFERS_SUCCESS = 'GET_JOB_OFFERS_SUCCESS'
export const GET_JOB_OFFER = 'GET_JOB_OFFER'
export const GET_JOB_OFFER_SUCCESS = 'GET_JOB_OFFER_SUCCESS'
export const DELETE_JOB_OFFER = 'DELETE_JOB_OFFER'
export const DELETE_JOB_OFFER_SUCCESS = 'DELETE_JOB_OFFER_SUCCESS'
export const UPSERT_JOB_OFFER = 'UPSERT_JOB_OFFER'
export const UPSERT_JOB_OFFER_SUCCESS = 'UPSERT_JOB_OFFER_SUCCESS'
export const UPDATE_APPLICATION_TIME = 'UPDATE_APPLICATION_TIME'
export const UPDATE_APPLICATION_TIME_SUCCESS = 'UPDATE_APPLICATION_TIME_SUCCESS'
export const UPDATE_APPLICATION_FLOW = 'UPDATE_APPLICATION_FLOW'
export const UPDATE_APPLICATION_FLOW_SUCCESS = 'UPDATE_APPLICATION_FLOW_SUCCESS'
export const JOB_OFFER_FICHE = 'JOB_OFFER_FICHE'
export const JOB_OFFER_FICHE_SUCCESS = 'JOB_OFFER_FICHE_SUCCESS'
export const JOB_OFFER_PROPOSAL = 'JOB_OFFER_PROPOSAL'
export const JOB_OFFER_PROPOSAL_SUCCESS = 'JOB_OFFER_PROPOSAL_SUCCESS'
export const JOB_OFFER_CONTRACT = 'JOB_OFFER_CONTRACT'
export const JOB_OFFER_CONTRACT_SUCCESS = 'JOB_OFFER_CONTRACT_SUCCESS'
export const JOB_OFFER_CONTRACT_SIGN = 'JOB_OFFER_CONTRACT_SIGN'
export const JOB_OFFER_CONTRACT_SIGN_SUCCESS = 'JOB_OFFER_CONTRACT_SIGN_SUCCESS'
export const JUST_JOB_OFFER_PROPOSAL = 'JUST_JOB_OFFER_PROPOSAL'
export const JOB_OFFER_CONTRACT_PROPOSAL = 'JOB_OFFER_CONTRACT_PROPOSAL'
export const JOB_OFFER_CONTRACT_PROPOSAL_SUCCESS = 'JOB_OFFER_CONTRACT_PROPOSAL_SUCCESS'
export const JOB_OFFER_CONTRACT_END = 'JOB_OFFER_CONTRACT_END'
export const JOB_OFFER_CONTRACT_END_SUCCESS = 'JOB_OFFER_CONTRACT_END_SUCCESS'

/*=========== Search ==========*/
export const SET_SEARCH_STATE = 'SET_SEARCH_STATE'
export const GET_SEARCH = 'GET_SEARCH'
export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS'
export const SAVE_SEARCH = 'SAVE_SEARCH'
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS'
export const DELETE_SEARCH = 'DELETE_SEARCH'
export const DELETE_SEARCH_SUCCESS = 'DELETE_SEARCH_SUCCESS'
export const SEARCH_ACTION = 'SEARCH_ACTION'
export const SEARCH_ACTION_SUCCESS = 'SEARCH_ACTION_SUCCESS'
export const OPEN_FILTER_JOB = 'OPEN_FILTER_JOB'
export const CLOSE_FILTER_JOB = 'CLOSE_FILTER_JOB'
export const UPLOAD_CV_WARNING = 'UPLOAD_CV_WARNING'
export const TALENT_ALREADY_APPLIED = 'TALENT_ALREADY_APPLIED'
export const SEARCH_MATCH = 'SEARCH_MATCH'
export const SEARCH_MATCH_SUCCESS = 'SEARCH_MATCH_SUCCESS'

/*=========== COMPANY ==========*/
export const SET_COMPANY_STATE = 'SET_COMPANY_STATE'
export const GET_CONTACT = 'GET_CONTACT'
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const REFETCH_CONTACT = 'REFETCH_CONTACT'
export const REFETCH_COMPANY = 'REFETCH_COMPANY'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const SEND_INVITATION = 'SEND_INVITATION'
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS'
export const GET_COMPANY_GROUPS= 'GET_COMPANY_GROUPS'
export const GET_COMPANY_GROUPS_SUCCESS= 'GET_COMPANY_GROUPS_SUCCESS'
export const SET_CONTACT_STATE= 'SET_CONTACT_STATE'
export const SET_COMPANY_VALUE_STATE= 'SET_COMPANY_VALUE_STATE'
export const SET_COMPANY_VALUE_ORDER= 'SET_COMPANY_VALUE_ORDER'
export const GET_COMPANY_TEAM= 'GET_COMPANY_TEAM'
export const GET_COMPANY_TEAM_SUCCESS= 'GET_COMPANY_TEAM_SUCCESS'
export const GET_COMPANY_INVOICE= 'GET_COMPANY_INVOICE'
export const GET_COMPANY_INVOICE_SUCCESS= 'GET_COMPANY_INVOICE_SUCCESS'
export const GET_COMPANY_INVOICE_SINGLE= 'GET_COMPANY_INVOICE_SINGLE'
export const GET_COMPANY_INVOICE_SINGLE_SUCCESS= 'GET_COMPANY_INVOICE_SINGLE_SUCCESS'
export const UPDATE_COMPANY_INVOICE= 'UPDATE_COMPANY_INVOICE'
export const UPDATE_COMPANY_INVOICE_SUCCESS= 'UPDATE_COMPANY_INVOICE_SUCCESS'
export const GET_COMPANY_INVOICE_TICKET= 'GET_COMPANY_INVOICE_TICKET'
export const GET_COMPANY_INVOICE_TICKET_SUCCESS= 'GET_COMPANY_INVOICE_TICKET_SUCCESS'
export const GET_COMPANY_INVOICE_VALIDATE= 'GET_COMPANY_INVOICE_VALIDATE'
export const GET_COMPANY_INVOICE_VALIDATE_SUCCESS= 'GET_COMPANY_INVOICE_VALIDATE_SUCCESS'
export const GET_COMPANY_INVOICE_VALIDATE_ITEM= 'GET_COMPANY_INVOICE_VALIDATE_ITEM'
export const GET_COMPANY_INVOICE_VALIDATE_ITEM_SUCCESS= 'GET_COMPANY_INVOICE_VALIDATE_ITEM_SUCCESS'

/*=========== CHATGPT ==========*/
export const GET_CHATGPT = 'GET_CHATGPT'
export const GET_CHATGPT_SUCCESS = 'GET_CHATGPT_SUCCESS'
export const CLEAR_JOB_ID_CHATGPT = 'CLEAR_JOB_ID_CHATGPT'
export const SET_GPT_STATE = 'SET_GPT_STATE'
export const CHECK_JOB = 'CHECK_JOB'
export const CHECK_JOB_SUCCESS = 'CHECK_JOB_SUCCESS'

/*=========== PORTAGE ==========*/
export const GET_PORTAGE = 'GET_PORTAGE'
export const GET_PORTAGE_SUCCESS = 'GET_PORTAGE_SUCCESS'
export const STORE_PORTAGE = 'STORE_PORTAGE'
export const STORE_PORTAGE_SUCCESS = 'STORE_PORTAGE_SUCCESS'
export const CHECK_COMPANY_PORTAGE = 'CHECK_COMPANY_PORTAGE'
export const CHECK_COMPANY_PORTAGE_SUCCESS = 'CHECK_COMPANY_PORTAGE_SUCCESS'